<template lang="html">
  <div class="Home"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Content from '@/components/Elements/Content.vue';

export default {
  components: {
    //Content
  },

  computed: {
    ...mapGetters(["pageData", "pageList"]),
  },
  methods: {
    ...mapActions(["fetchItems"]),
  },
  created() {
    this.$store.state.hideFooter = true;
    this.fetchItems({
      table: "settings",
      target: "setPageList",
    });
  },
};
</script>
